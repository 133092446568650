import { Card, Image, Text, Group, Badge, Button, ActionIcon, BackgroundImage, Rating } from '@mantine/core';
import classes from './../../styles/ProductCard.module.css';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import useStore from '../../store/useStore';
import { css } from '@linaria/core';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';


interface Props {
  id: string;
  title: string;
  image: string;
  price: number;
  priceAfterDiscount: number;
  rating?: number;
}

const cardStyles = (color: string) => css`
  cursor: pointer;

  &:hover {
    background-color: red;
  }
`;

export function ProductCard({ id, image, title, price, priceAfterDiscount, rating }: Props) {
  const matches = useMediaQuery('(max-width: 36em)');
  const dataStore = useStore((state: any) => state.store);

  
    return (
        <Card withBorder radius="md" p="md" className={classes.card} >
            <Card.Section style={{position: 'relative'}} className={classes.firstSection}>
                {/* <Image src={image} alt={title} w={"100%"} h={"100%"} pos={'absolute'} fit={'fill'} /> */}
                <LazyLoadImage
                    src={`${process.env.REACT_APP_API_URL_IMAGES}/${image}.webp`}
                    alt={""}
                    height={"100%"}
                    width={"100%"}
                    delayTime={0}
                    style={{position: "absolute", objectFit: "fill"}}
                    placeholderSrc={`${process.env.REACT_APP_API_URL_THUMBNAILS}/${image}.webp`}
                />
                {priceAfterDiscount > 0
                    ? <Badge variant="filled" color={"red"} c={dataStore?.information?.textColor || "#fff"}  size='lg' className={classes.badge} radius={0}>
                        تخفيض
                    </Badge>
                    : null
                }
            </Card.Section>

            <Card.Section className={classes.section} mt="md">
                <Text fz={matches ? "md" : "lg"} fw={500} component={Link} to={`/product/${id}`} className={classes.link}>
                    {title}
                </Text>
                {rating && rating > 0
                    ? <Rating value={rating} fractions={2} readOnly />
                    : null
                }
            </Card.Section>
        
            <Card.Section className={classes.section}>
                <Group gap={10}>
                    <Text size={matches ? "sm" : "md"} fw={'bold'} className={classes.label} c="red">
                        {priceAfterDiscount > 0 ? priceAfterDiscount : price} د.ج
                    </Text>
                    {priceAfterDiscount > 0 
                        ? <Text size={matches ? "xs" : "sm"} fw={'bold'} className={classes.label} c="dark" td="line-through">
                            {price} د.ج
                            </Text>
                        : null
                    }
                </Group>
            </Card.Section>
        
            <Group flex={"auto"} mt={"xs"} align='flex-end'>
                <Button 
                    variant='filled' radius="md" style={{ flex: 1 }} color={dataStore?.information?.backgroundColor || "#d84a2f"}
                    component={Link} to={`/product/${id}`} c={dataStore?.information?.textColor || "#fff"}
                    
                    // className={classes.button}
                >
                    اشتري الآن
                </Button>
            </Group>
        </Card>
    );
}